import { ReactElement } from 'react';
import '../styles/main.css';
import Michael from '../images/michael.png';

function HowAmieWorks(): ReactElement {
  return (
    <section className="bg-black">
      <div className="container w-11/12 mx-auto py-14">
        <h2 className="text-white text-4xl">How AMIE Works</h2>
        <div className="md:flex md:flex-wrap">
          <div className="flex-1">
            <div className="max-w-sm  mx-auto my-5 h-full">
              <img src={Michael} className="w-full h-full object-contain" alt="Michael" />
            </div>
          </div>
          <div className="flex-1 md:pl-5">
            <div className="card-bg rounded-md px-5 py-2 lg:w-9/12 my-10 md:mx-0 sm:mx-auto">
              <h3 className="font-bold text-xl pb-4">Activate</h3>
              <p className="font-normal text-lg">Add the eSIM to your phone and activate it within seconds in our app. No SIM card required.</p>
            </div>
            <div className="card-bg rounded-md px-5 py-2 lg:w-9/12 my-10 md:mx-0 sm:mx-auto">
              <h3 className="font-bold text-xl pb-4">Shop</h3>
              <p className="font-normal text-lg">Choose from high-speed, prepaid data or backup plans. From 1GB to 10GB.</p>
            </div>
            <div className="card-bg rounded-md px-5 py-2 lg:w-9/12 my-10 md:mx-0 sm:mx-auto">
              <h3 className="font-bold text-xl pb-4">Use</h3>
              <p className="font-normal text-lg">Start using data immediately. Monitor your usage real time directly within the app. Top-up at anytime.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowAmieWorks;
