import { ReactElement } from 'react';
import union from '../images/Union (2).png';
import vector1 from '../images/Vector (1).png';
import vector2 from '../images/Vector (2).png';
import vector4 from '../images/Vector (4).png';

function Footer(): ReactElement {
  return (
    <section className="bg-black">
      <div className="container w-11/12 mx-auto pt-6 pb-12">
        <div
          className="footer flex-wrap md:flex-row flex justify-between md:items-center"
        >
          <div className="footer-amia">
            <img src={union} alt="" className="w-24 md:w-20 mb-3" />
            <h5
              className="text-white w-56 leading-7 md:w-auto md:leading-6 font-medium text-lg"
            >
              Get a prepaid data plan
              <br />
              or a backup plan to have coverage
              <br />
              all over the USA
            </h5>
            <div className="icons flex py-4 md:py-3">
              <img src={vector1} alt="images" className="w-8 mr-4" />
              <img src={vector2} alt="images" className="w-8 mx-4" />
              <img src={vector4} alt="images" className="w-8 mx-4" />
            </div>
          </div>

          <div className="contact md:pb-5 md:ml-4 md:ml-0">
            <ul className="text-white" key="1">
              <li
                className="py-2 px-1 text-lg leading-5 font-bold"
                key="2"
              >
                Contacts
              </li>
              <li
                className="py-1 pt-3 md:pt-0 text-base leading-2 font-medium"
                key="3"
              >
                +1 (777) 777-7777
              </li>
              <li
                className="text-base leading-2 font-medium"
                key="4"
              >
                mail@amie.com
              </li>
            </ul>
          </div>
          <div className="home items-center pt-9 md:pt-14 mr-8 md:ml-5 md:ml-0">
            <ul className="text-white">
              <li className="text-lg leading-5 font-bold" key="1">HOME</li>
              <li
                className="text-base leading-1 font-medium my-1"
                key="2"
              >
                About
              </li>
              <li
                className="text-base leading-1 font-medium my-1"
                key="3"
              >
                Pricing
              </li>
              <li className="text-base leading-1 font-medium my-1" key="4">
                How
                it works
              </li>
              <li className="text-base leading-1 font-medium my-1" key="5">
                FAQ
              </li>
              <li className="text-base leading-1 font-medium my-1" key="6">
                Get
                help
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
