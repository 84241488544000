import { ReactElement } from 'react';
import '../styles/main.css';
import { useStaticQuery, graphql } from 'gatsby';
import Header from './header';
import img1 from '../images/img1.png';
import appleStore from '../images/appleStore.svg';

interface TabProps {
  tabText: string;
  active: boolean;
}

export function Tab({ tabText, active }: TabProps): ReactElement {
  return (
    <li
      className={` w-1/3 mx-2 h-[76px] text-center md:py-5 md:px-6 px-2 py-1 rounded-t-xl grow flex items-center justify-center ${active ? 'bg-white' : 'bg-black text-white'}`}
    >
      <span className="md:text-2xl  text-sm font-bold block">
        {tabText}
      </span>
    </li>
  );
}

function Banner(): ReactElement {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <section className="banner">
      <div
        className="bg-no-repeat bg-cover bg-mobile md:bg-nonMobile"
        style={{ backgroundImage: `url(${img1})` }}
      >
        <Header siteTitle={data.site.siteMetadata?.title || 'Title'} />
        <div className="container w-11/12 mx-auto mt-96 md:mt-64">
          <div className="md:w-8/12">
            <h2 className="text-3xl xl:text-6xl font-bold ">
              Get a prepaid data plan
              or a backup plan to have coverage all over the USA
            </h2>
            <p className="my-2 md:my-9 font-normal text-2xl">
              eSIM prepaid plans for
              iPhones
            </p>
            <button
              className=" rounded md:rounded-md w-40 h-15"
            >
              <img src={appleStore} alt="img-apple-store" className="h-full" />
            </button>
            <h2 className="text-white md:text-black text-3xl  md:text-4xl font-bold mt-14 md:mt-24 mb-8">
              Plans and
              Packages
            </h2>
          </div>
          <ul className="flex w-full" key="c3213f">
            <Tab tabText="US Backup Line" active key="3213" />
            <Tab tabText="US Data Packages" active={false} key="32c1213" />
            <Tab
              tabText="International Packages"
              active={false}
              key="321c1233"
            />
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Banner;
