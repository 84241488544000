import { ReactElement } from 'react';
import '../styles/main.css';
import img4 from '../images/img4.png';
import appleStore from '../images/appleStore.svg';

function GetPrePaid(): ReactElement {
  return (
    <section className="md:py-14">
      <div className=" get-prepard container mx-auto  relative">
        <div
          className=" praperd-content w-full h-56 md:h-80 rounded-xl "
          style={{ backgroundColor: '#C5A0FF' }}
        >
          <div className="w-full md:w-full xl:w-4/6">
            <h3
              className="text-center lg:text-left px-4 py-5 text-xl md:text-3xl lg:text-4xl font-bold lg:px-8 lg:pt-10 "
              style={{ lineHeight: '56px', color: '#18191F' }}
            >
              Get a prepaid data
              plan or a backup plan to have coverage all over the USA
            </h3>
            <button
              className="rounded md:rounded-md  ml-6  w-40 h-15"
            >
              <img src={appleStore} alt="img-apple-store" className="h-full" />
            </button>
          </div>
        </div>
        <div
          className="backup-plane absolute right-10 "
          style={{ top: '-100px;' }}
        >
          <img src={img4} alt="" style={{ width: '410px', height: '520px' }} />
        </div>
      </div>
    </section>
  );
}

export default GetPrePaid;
