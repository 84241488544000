/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import { ReactElement } from 'react';
import GetPrePaidSection from 'src/components/GetPrePaidSection';
import HowAmieWorks from 'src/components/HowAmieWorks';
import WhyAmie from 'src/components/WhyAmieSection';

import Banner from './Banner';
import GetPaidPlan from './GetPaidSection';
import Plans from './Plans';
import Footer from './footer';

function Layout(): ReactElement {
  return (
    <main>
      <Banner />
      <Plans />
      <GetPrePaidSection />
      <WhyAmie />
      <HowAmieWorks />
      <GetPaidPlan />
      <Footer />
    </main>
  );
}

Layout.propTypes = {};

export default Layout;
