import { ReactElement } from 'react';
import '../styles/main.css';

function WhyAmie(): ReactElement {
  const whyamie = [
    { title: 'Say bye to expensive on-demand data', desc: "Whether you're traveling internationally or just need and extra gig or two of data in a pinch, AMIE helps get you connected to top mobile networks at an affordable rate." },
    { title: 'Get connected in minutes', desc: 'Forget about SIM card booths and long hold-times. On Nomad, you can browse, purchase, and activate a data plan within minutes. Most users activate a data plan within 5 minutes of downloading the app.' },
    { title: 'Take control of your consumption', desc: 'Data usage isnot always predictable, so we made it easy to top-up directly within the AMIE app.You can add data whenever you want, and you only pay for what you need.' },
    { title: 'Never pay an overage fee again', desc: 'Nomad is 100% prepaid with no hidden fees. On Nomad, you only pay what you see upfront. If you\'re not satisfied, we will give you a refund on all unused data. No questions asked.' },
  ];
  return (
    <section className="whyamie pb-14">
      <div className="container mx-auto">
        <h2 className="px-7 mt-9 md:mt-24 text-xl md:text-2xl lg:text-5xl font-bold pb-20">Why AMIE</h2>
        <div className="grid gird-cols-1 md:grid-cols-2 gap-4 ">
          {whyamie.map((item) => (
            <div className="col-span-1" key={item.title}>
              <h6 className=" font-bold px-7 text-lg mb-7 relative ">{item.title}</h6>
              <p className="px-7 text-sm">
                {item.desc}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default WhyAmie;
