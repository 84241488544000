import { ReactElement } from 'react';

function Card({
  data, calls, sms, cover, remaining, value, title,
}: any): ReactElement {
  const textStyles = 'text-sm md:text-lg';

  return (
    <div
      className="rounded-xl mb-5 relative flex basis-1/4 flex-col max-h-72 md:max-h-96"
      style={{ backgroundColor: '#E8E8E8', height: '414px' }}
    >
      <div className="mb-4">
        <h5
          className="text-lg md:text-2xl font-bold leading-none text-darkGray text-center pt-4 sm:pt-8"
          style={{ color: '#333333' }}
        >
          {title}
        </h5>
      </div>
      <div className="flow-root py-3 px-4 lg:px-8 lg:py-6 ">
        <ul role="list" style={{ color: '#333333' }}>
          <li className="py-2 sm:py-3">
            <div className="flex items-center " style={{ color: '#333333' }}>
              <div className="flex-1">
                <p className={`${textStyles} font-medium `}>
                  Data:
                </p>
              </div>
              <div
                className={`inline-flex items-center ${textStyles} font-bold `}
              >
                {data}
              </div>
            </div>
          </li>
          {calls && (
            <li className="py-3 sm:py-2">
              <div className="flex items-center">
                <div className="flex-1">
                  <p className={`${textStyles} font-medium `}>
                    Calls:
                  </p>
                </div>
                <div
                  className={`inline-flex items-center ${textStyles} font-bold `}
                >
                  {calls}
                </div>
              </div>
            </li>
          )}
          {sms && (
            <li className="py-3 sm:py-2">
              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  <p className={`${textStyles} font-medium `}>
                    SMS:
                  </p>
                </div>
                <div
                  className={`inline-flex items-center ${textStyles} font-bold `}
                >
                  {sms}
                </div>
              </div>
            </li>
          )}
          <li className="py-3 sm:py-2">
            <div className="flex items-center space-x-4">
              <div className="flex-1 min-w-0">
                <p className={`${textStyles} font-medium `}>
                  Coverage:
                </p>
              </div>
              <div
                className={`inline-flex items-center  ${textStyles} font-bold `}
              >
                {cover}
              </div>
            </div>
          </li>
          {remaining && (
            <li className="py-3 sm:py-2">
              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  <p className={`${textStyles} font-medium `}>
                    Remaining:
                  </p>
                </div>
                <div
                  className={`inline-flex items-center font-bold ${textStyles}`}
                >
                  {remaining}
                </div>
              </div>
            </li>
          )}
        </ul>
      </div>
      <div
        className="w-full text-center h-10 lg:h-20 my-auto absolute bottom-0 rounded-b-xl"
        style={{ backgroundColor: '#74E1F0' }}
      >
        <button
          className="text-center my-auto items-center h-full text-xl md:text-2xl font-semibold"
        >
          {value}
        </button>
      </div>
    </div>
  );
}

export default Card;
