import { ReactElement } from 'react';
import '../styles/main.css';
import appleStore from 'src/images/appleStore.svg';
import iphone1 from 'src/images/iphone1.png';
import iphone2 from 'src/images/iphone2.png';

function GetPaidPlan(): ReactElement {
  return (
    <section
      className="bg-getPaidSectionBackgroundColor max-h-full	md:max-h-96 h-full md:h-96 "
    >
      <div
        className="mx-4  md:mx-32 relative  flex  flex-col-reverse md:flex-row justify-between"
      >
        <div
          className="col-span-1 md:col-span-3 flex items-left justify-center flex-col h-full max-h-full"
        >
          <h2 className="font-bold text-3xl max-w-xl	mt-12">
            Get a prepaid data plan or a backup plan to have coverage all
            over the USA
          </h2>
          <p className="font-normal text-lg text-gray-900 pb-11">
            Designed to
            solve your connectivity problems wherever you are
          </p>
          <button
            className=" rounded md:rounded-md w-40 h-15 mb-16 md:mb-0"
          >
            <img src={appleStore} alt="img-apple-store" className="h-full" />
          </button>
        </div>
        <div className="flex justify-evenly">
          <img
            src={iphone1}
            alt="mob"
            className="w-40 md:w-48  mr-3 md:mr-14 transform -translate-y-10 md:-translate-y-16"
          />
          <img
            src={iphone2}
            alt="mob"
            className="w-40 md:w-48 transform -translate-y-10 md:-translate-y-16 md:mr-20"
          />
        </div>
      </div>
    </section>
  );
}

export default GetPaidPlan;
