import { ReactElement } from 'react';
import '../styles/main.css';
import Card from './card';

function Plans(): ReactElement {
  const plans = [
    {
      title: 'US Backup Plan',
      value: 'Only $9.95',
      coverValue: 'USA',
      smsValue: '500 SMS',
      dataValue: '5 Gb',
      remainingValue: '30 days',
      calls: '500 mins',
    },
    {
      title: 'US Data Line',
      value: 'Only $5.95',
      coverValue: 'USA',
      dataValue: '5 Gb',
      remainingValue: '30 days',
    },
    {
      title: 'US Backup Plan',
      value: 'Only $9.95',
      coverValue: 'USA',
      smsValue: '1000 SMS',
      dataValue: '10 Gb',
      remainingValue: '30 days',
      calls: '1000 mins',
    },
    {
      title: 'US Data Line',
      value: 'Only $5.95',
      coverValue: 'USA',
      dataValue: '5 Gb',
      remainingValue: '30 days',
    },
  ];
  return (
    <section>
      <div className=" mx-auto  flex  py-14 flex-wrap justify-evenly">
        {plans.map((item, index): any => (
          <Card
            key={item.dataValue}
            data={item.dataValue}
            value={`${item.value}${index}`}
            calls={item.calls}
            cover={item.coverValue}
            remaining={item.remainingValue}
            title={item.title}
          />
        ))}
      </div>
    </section>
  );
}

export default Plans;
